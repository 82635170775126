import axios from 'axios'
import qs from 'qs'
import { MessageBox } from 'element-ui'
// 引入vue-i18n插件
import VueI18n from 'vue-i18n'

// 定义中英文语言包
const messages = {
  en: {
    message: {
      // 登录超时
      'loginTimeout': 'login timeout',
      // 登录信息超时, 请重新登录!
      'loginTimeoutPleaseRetry': 'Login timeout, please retry!',
      // 确定
      'ok': 'OK'
    }
  },
  zh: {
    message: {
      // 登录超时
      'loginTimeout': '登录超时',
      // 登录信息超时, 请重新登录!
      'loginTimeoutPleaseRetry': '登录信息超时, 请重新登录!',
      // 确定
      'ok': 'OK'
    }
  }
}
// 实例化一个i18n对象
let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
const i18n = new VueI18n({
  locale: lang, // 语言标识
  messages: messages // 上面自己定义的语言包
})

var baseURL = '';
if (process.env.NODE_ENV === 'development') { // 开发环境
  baseURL = 'http://alliance_api.hractual.com/'
  // baseURL = 'http://advunion.test/'
} else if (process.env.NODE_ENV === 'test') { // 测试环境
  baseURL = 'http://alliance_api.hractual.com/'
} else if (process.env.NODE_ENV === 'production ') { // 生产环境
  baseURL = 'https://www.advunion.cc/'
}
// 自己创建一个axios对象
const request = axios.create({
    baseURL: baseURL,   // 基础路径，默认是/ ，如果改了，会自动添加到你请求url前面
    // baseURL: 'http://advunion.test/',   // 基础路径，默认是/ ，如果改了，会自动添加到你请求url前面
    timeout: 5000   // 请求超时，5000毫秒
})
// 请求拦截器
request.interceptors.request.use( config => {
  if (config.method == 'post') {
    // let anyue_token =
    let data = {
      ...config.data,
      anyue_token: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')).anyue_token : ''
    }
    config.data = qs.stringify(data)
  }
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 处理请求错误
  return Promise.reject(error);
});

// 相应拦截器
request.interceptors.response.use( response => {
  // 在2xx范围内的任何状态代码都会触发此功能
  if (response.status == 200) {

    // 登录超时(token 失效)
    if (response.data.code == 1000) {
      return MessageBox.alert(i18n.tc('message.loginTimeoutPleaseRetry'), i18n.tc('message.loginTimeout'), {
        confirmButtonText: i18n.tc('message.ok'),
        callback: action => {
          window.location.href = '/#/login'
          window.location.reload()
        }
      })
    }
    return response.data
  }
  // 处理响应数据
  return response;
}, function (error) {
  // 任何超出2xx范围的状态代码都会触发此功能
  // 处理响应错误
  return Promise.reject(error);
});

export default request  // 导出自定义创建的 axios 对象
