// import axios from './request'
import axios from './axios'
// console.log(axios.post('/v2.user', { anyue_token: '290213e6a58b6a57e858e0cfb817fc54'}}))
// 登录接口 已完成
export const login = (data) => {
  return axios.request({ url: '/v2.login', method: 'post', data })
}
// 退出登录 已完成
export const outLogin = (data) => {
  return axios.request({ url: '/v2.login/out', method: 'post', data })
}
// 重置密码 已完成
export const rspwd = (data) => {
  return axios.request({ url: '/v2.user/rspwd', method: 'post', data })
}
// 合作信息 已完成
export const user = (data) => {
  // axios.post('/v2.user', { anyue_token: '290213e6a58b6a57e858e0cfb817fc54'})
  return axios.request({ url: '/v2.user', method: 'post', data})
}
// 获取收款信息 已完成
export const getPay = (data) => {
  return axios.request({ url: '/v2.pay', method: 'post', data})
}
// 添加/修改收款信息 已完成
export const dealPay = (data) => {
  return axios.request({ url: '/v2.pay/deal', method: 'post', data})
}


// 数据中心列表 已完成
export const dataList = (data) => {
  return axios.request({ url: '/v2.idc', method: 'post', data })
}

// 小说管理列表 已完成
export const bookList = (data) => {
  return axios.request({ url: '/v2.book/index', method: 'post', data })
}
// 小说详情 已完成
export const bookDetail = (data) => {
  return axios.request({ url: '/v2.book/detail', method: 'post', data })
}
// 章节信息 已完成
export const sectionList = (data) => {
  return axios.request({ url: '/v2.section/list', method: 'post', data })
}

// 生成推广链接 已完成
export const generateLink = (data) => {
  return axios.request({ url: '/v2.promotionLink/generateLink', method: 'post', data })
}
// 推广列表  已完成
export const promotionLink = (data) => {
  return axios.request({ url: '/v2.promotionLink/list', method: 'post', data })
}
// 结算管理 已完成
export const settle = (data) => {
  return axios.request({ url: '/v2.settle', method: 'post', data })
}
// channelList
export const channelList = (data) => {
  return axios.request({ url: '/v2.channel', method: 'post', data })
}
